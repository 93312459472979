import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Container,
  generateUtilityClasses,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { DigitalSectionCard } from './digital-section-card';
import Button from '@grimme/ui-components/button';
import Link from 'next/link';
import { LandingPageData } from '@/lib/butter-types';

const classes = generateUtilityClasses('DigitalSection', [
  'backgroundBox',
  'containerWrapper',
  'grid',
  'label',
  'marginTop2',
  'stack',
]);

const StyledStack = styled(Stack)(({ theme }) => ({
  [`& .${classes.label}`]: {
    textTransform: 'uppercase',
  },
  [`& .${classes.marginTop2}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.stack}`]: {
    marginTop: theme.spacing(4),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  [`& .${classes.backgroundBox}`]: {
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    top: 200,
    left: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      top: 300,
    },
  },
  [`& .${classes.containerWrapper}`]: {
    position: 'relative',
  },
  [`& .${classes.grid}`]: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

export function DigitalSection(props: LandingPageData['grimme_digital']) {
  const {
    label,
    headline,
    description,
    secondary_button_text,
    secondary_button_url,
    primary_button_text,
    primary_button_url,
    left_image,
    left_image_alt,
    left_title,
    left_description,
    right_image,
    right_image_alt,
    right_title,
    right_description,
  } = props;

  return (
    <Box component="section">
      <Container>
        <StyledStack>
          <Grid
            container
            spacing={{ xs: '12px', sm: '16px', lg: '24px' }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={4} sm={8}>
              <Typography
                className={`${classes.label} g-typography-h6`}
                component="p"
              >
                {label}
              </Typography>
              <Typography
                className={`${classes.marginTop2} g-typography-h2`}
                component="h1"
              >
                {headline}
              </Typography>
              <Typography className={classes.marginTop2} component="p">
                {description}
              </Typography>
              <Stack className={classes.stack} direction="row" spacing={2}>
                <Button asChild variant="outline">
                  <Link href={secondary_button_url}>
                    {secondary_button_text}
                    <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
                  </Link>
                </Button>
                <Button asChild variant="primary">
                  <Link href={primary_button_url}>
                    {primary_button_text}
                    <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
                  </Link>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </StyledStack>
      </Container>
      <StyledBox>
        <Box className={classes.backgroundBox} />
        <Container className={classes.containerWrapper}>
          <Grid
            className={classes.grid}
            container
            direction={{ xs: 'column', md: 'row' }}
            columnSpacing={6}
            rowSpacing={6}
          >
            <DigitalSectionCard
              image={left_image}
              alt={left_image_alt}
              title={left_title}
              description={left_description}
            />
            <DigitalSectionCard
              image={right_image}
              alt={right_image_alt}
              title={right_title}
              description={right_description}
            />
          </Grid>
        </Container>
      </StyledBox>
    </Box>
  );
}
