import { Button } from '@grimme/components';

export const PreviewModeButton = () => {
  return (
    <Button
      className="fixed top-[13.5px] left-0 z-[9999999]"
      href="/api/exit-preview"
      variant="primary"
    >
      Exit Preview Mode
    </Button>
  );
};
