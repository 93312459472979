import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@grimme/components';
// import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useState } from 'react';
import { StyledButton, StyledForm, classes } from './styles';
import { useGeolocation } from '@/hooks';
import { DEFAULT_LOCALE } from '@/utils';
import TextInput from '@grimme/ui-components/text-input';
import Select from '@grimme/ui-components/select';
import { countriesList } from '../service-appointment-section/dialog/forms/utils';

export interface LocationSearchFormProps {
  action?: string;
  countrySelectLabel: string;
  submitLabel: string;
  zipCodeTextfieldLabel: string;
}

export function LocationSearchForm(props: LocationSearchFormProps) {
  const { action, countrySelectLabel, submitLabel, zipCodeTextfieldLabel } =
    props;
  const geolocation = useGeolocation();
  // const insights = useAppInsightsContext();
  const [formData, setFormData] = useState({
    countryCode: geolocation.countryCode || DEFAULT_LOCALE,
    zipCode: '',
  });

  /*
    NOTE: This handleOnSubmit was never working because the onSubmit was never passed
    to the component anywhere.
  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!onSubmit) {
      return;
    }
    onSubmit && onSubmit(formData);
    insights?.trackEvent({
      name: "Search Partner",
      properties: {
        country: formData.countryCode,
        zipCode: formData.zipCode,
      },
    });
  };
  */

  const setFormValue = (key: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <StyledForm action={action && action} title="location-search-form">
      <Select
        size="sm"
        className={classes.countrySelect}
        label={countrySelectLabel}
        name="countryCode"
        onValueChange={(e: string) => setFormValue('countryCode', e)}
        options={countriesList}
        value={formData.countryCode}
      />
      <TextInput
        className="w-fit"
        size="sm"
        label={zipCodeTextfieldLabel}
        onChange={(e) => setFormValue('zipCode', e.target.value)}
        value={formData.zipCode}
      />

      <StyledButton type="submit" variant="primary">
        {submitLabel}
        <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
      </StyledButton>
    </StyledForm>
  );
}
