type CountriesType = {
  isocode: string;
  name: string;
  prefix: string;
}[];

// TODO: Add those flags in the library again
// NOTE: Currently a couple of countries are disabled, because they are not available on the cdn
export const Countries: CountriesType = [
  {
    isocode: 'AD',
    name: 'Andorra',
    prefix: '+376',
  },
  {
    isocode: 'AE',
    name: 'دولة الإمارات العربية المتحدة',
    prefix: '+971',
  },
  {
    isocode: 'AF',
    name: 'افغانستان',
    prefix: '+93',
  },
  {
    isocode: 'AG',
    name: 'Antigua and Barbuda',
    prefix: '+1-268',
  },
  {
    isocode: 'AI',
    name: 'Anguilla',
    prefix: '+1-264',
  },
  {
    isocode: 'AL',
    name: 'Shqipëria',
    prefix: '+355',
  },
  {
    isocode: 'AM',
    name: 'Հայաստան',
    prefix: '+374',
  },
  // {
  //   isocode: "AN",
  //   name: "Netherlands Antilles",
  //   prefix: "+599",
  // },
  {
    isocode: 'AO',
    name: 'Angola',
    prefix: '+244',
  },
  {
    isocode: 'AQ',
    name: 'Antarctica',
    prefix: '+672',
  },
  {
    isocode: 'AR',
    name: 'Argentina',
    prefix: '+54',
  },
  {
    isocode: 'AS',
    name: 'American Samoa',
    prefix: '+1-684',
  },
  {
    isocode: 'AT',
    name: 'Österreich',
    prefix: '+43',
  },
  {
    isocode: 'AU',
    name: 'Australia',
    prefix: '+61',
  },
  {
    isocode: 'AW',
    name: 'Aruba',
    prefix: '+297',
  },
  {
    isocode: 'AZ',
    name: 'Azərbaycan',
    prefix: '+994',
  },
  {
    isocode: 'BA',
    name: 'Bosna i Hercegovina',
    prefix: '+387',
  },
  {
    isocode: 'BB',
    name: 'Barbados',
    prefix: '+1-246',
  },
  {
    isocode: 'BD',
    name: 'Bangladesh',
    prefix: '+880',
  },
  {
    isocode: 'BE',
    name: 'België',
    prefix: '+32',
  },
  {
    isocode: 'BF',
    name: 'Burkina Faso',
    prefix: '+226',
  },
  {
    isocode: 'BG',
    name: 'България',
    prefix: '+359',
  },
  {
    isocode: 'BH',
    name: '‏البحرين',
    prefix: '+973',
  },
  {
    isocode: 'BI',
    name: 'Burundi',
    prefix: '+257',
  },
  {
    isocode: 'BJ',
    name: 'Bénin',
    prefix: '+229',
  },
  {
    isocode: 'BM',
    name: 'Bermuda',
    prefix: '+1-441',
  },
  {
    isocode: 'BN',
    name: 'Negara Brunei Darussalam',
    prefix: '+673',
  },
  {
    isocode: 'BO',
    name: 'Bolivia',
    prefix: '+591',
  },
  {
    isocode: 'BR',
    name: 'Brasil',
    prefix: '+55',
  },
  {
    isocode: 'BS',
    name: 'Bahamas',
    prefix: '+1-242',
  },
  {
    isocode: 'BT',
    name: 'Druk Yul',
    prefix: '+975',
  },
  {
    isocode: 'BV',
    name: 'Bouvetøya',
    prefix: '+47',
  },
  {
    isocode: 'BW',
    name: 'Botswana',
    prefix: '+267',
  },
  {
    isocode: 'BY',
    name: 'Белару́сь',
    prefix: '+375',
  },
  {
    isocode: 'BZ',
    name: 'Belize',
    prefix: '+501',
  },
  {
    isocode: 'CA',
    name: 'Canada',
    prefix: '+1',
  },
  {
    isocode: 'CD',
    name: 'République démocratique du Congo',
    prefix: '+243',
  },
  {
    isocode: 'CF',
    name: 'Ködörösêse tî Bêafrîka',
    prefix: '+236',
  },
  {
    isocode: 'CG',
    name: 'République du Congo',
    prefix: '+242',
  },
  {
    isocode: 'CH',
    name: 'Schweiz',
    prefix: '+41',
  },
  {
    isocode: 'CI',
    name: "Côte d'Ivoire",
    prefix: '+225',
  },
  {
    isocode: 'CK',
    name: 'Cook Islands',
    prefix: '+682',
  },
  {
    isocode: 'CL',
    name: 'Chile',
    prefix: '+56',
  },
  {
    isocode: 'CM',
    name: 'Cameroon',
    prefix: '+237',
  },
  {
    isocode: 'CN',
    name: '中国',
    prefix: '+86',
  },
  {
    isocode: 'CO',
    name: 'Colombia',
    prefix: '+57',
  },
  {
    isocode: 'CR',
    name: 'Costa Rica',
    prefix: '+506',
  },
  {
    isocode: 'CU',
    name: 'Cuba',
    prefix: '+53',
  },
  {
    isocode: 'CV',
    name: 'Cabo Verde',
    prefix: '+238',
  },
  {
    isocode: 'CX',
    name: 'Christmas Island',
    prefix: '+61',
  },
  {
    isocode: 'CY',
    name: 'Κύπρος',
    prefix: '+357',
  },
  {
    isocode: 'CZ',
    name: 'Česká republika',
    prefix: '+420',
  },
  {
    isocode: 'DE',
    name: 'Deutschland',
    prefix: '+49',
  },
  {
    isocode: 'DJ',
    name: 'Djibouti',
    prefix: '+253',
  },
  {
    isocode: 'DK',
    name: 'Danmark',
    prefix: '+45',
  },
  {
    isocode: 'DM',
    name: 'Dominica',
    prefix: '+1-767',
  },
  {
    isocode: 'DO',
    name: 'República Dominicana',
    prefix: '+1-809',
  },
  {
    isocode: 'DZ',
    name: 'الجزائر',
    prefix: '+213',
  },
  {
    isocode: 'EC',
    name: 'Ecuador',
    prefix: '+593',
  },
  {
    isocode: 'EE',
    name: 'Eesti',
    prefix: '+372',
  },
  {
    isocode: 'EG',
    name: 'مصر‎',
    prefix: '+20',
  },
  {
    isocode: 'ER',
    name: 'ኤርትራ',
    prefix: '+291',
  },
  {
    isocode: 'ES',
    name: 'España',
    prefix: '+34',
  },
  {
    isocode: 'ET',
    name: 'ኢትዮጵያ',
    prefix: '+251',
  },
  {
    isocode: 'FI',
    name: 'Suomi',
    prefix: '+358',
  },
  {
    isocode: 'FJ',
    name: 'Fiji',
    prefix: '+679',
  },
  {
    isocode: 'FK',
    name: 'Falkland Islands',
    prefix: '+500',
  },
  {
    isocode: 'FM',
    name: 'Micronesia',
    prefix: '+691',
  },
  {
    isocode: 'FO',
    name: 'Føroyar',
    prefix: '+298',
  },
  {
    isocode: 'FR',
    name: 'France',
    prefix: '+33',
  },
  {
    isocode: 'GA',
    name: 'Gabon',
    prefix: '+241',
  },
  {
    isocode: 'GB',
    name: 'United Kingdom',
    prefix: '+44',
  },
  {
    isocode: 'GD',
    name: 'Grenada',
    prefix: '+1-473',
  },
  {
    isocode: 'GE',
    name: 'საქართველო',
    prefix: '+995',
  },
  {
    isocode: 'GF',
    name: 'Guyane française',
    prefix: '+594',
  },
  {
    isocode: 'GH',
    name: 'Ghana',
    prefix: '+233',
  },
  {
    isocode: 'GI',
    name: 'Gibraltar',
    prefix: '+350',
  },
  {
    isocode: 'GL',
    name: 'Kalaallit Nunaat',
    prefix: '+299',
  },
  {
    isocode: 'GM',
    name: 'Gambia',
    prefix: '+220',
  },
  {
    isocode: 'GP',
    name: 'Guadeloupe',
    prefix: '+590',
  },
  {
    isocode: 'GQ',
    name: 'Guinea Ecuatorial',
    prefix: '+240',
  },
  {
    isocode: 'GR',
    name: 'Ελλάδα',
    prefix: '+30',
  },
  {
    isocode: 'GS',
    name: 'South Georgia',
    prefix: '+500',
  },
  {
    isocode: 'GT',
    name: 'Guatemala',
    prefix: '+502',
  },
  {
    isocode: 'GU',
    name: 'Guam',
    prefix: '+1-671',
  },
  {
    isocode: 'GW',
    name: 'Guiné-Bissau',
    prefix: '+245',
  },
  {
    isocode: 'GY',
    name: 'Guyana',
    prefix: '+592',
  },
  {
    isocode: 'HT',
    name: 'Haiti',
    prefix: '+509',
  },
  {
    isocode: 'HK',
    name: '香港',
    prefix: '+852',
  },
  {
    isocode: 'HM',
    name: 'Heard Island and McDonald Islands',
    prefix: '+672',
  },
  {
    isocode: 'HN',
    name: 'Honduras',
    prefix: '+504',
  },
  {
    isocode: 'HR',
    name: 'Hrvatska',
    prefix: '+385',
  },
  {
    isocode: 'HU',
    name: 'Magyarország',
    prefix: '+36',
  },
  {
    isocode: 'ID',
    name: 'Indonesia',
    prefix: '+62',
  },
  {
    isocode: 'IE',
    name: 'Éire',
    prefix: '+353',
  },
  {
    isocode: 'IL',
    name: 'יִשְׂרָאֵל',
    prefix: '+972',
  },
  {
    isocode: 'IN',
    name: 'भारत',
    prefix: '+91',
  },
  {
    isocode: 'IO',
    name: 'British Indian Ocean Territory',
    prefix: '+246',
  },
  {
    isocode: 'IQ',
    name: 'العراق',
    prefix: '+964',
  },
  {
    isocode: 'IR',
    name: 'ایران',
    prefix: '+98',
  },
  {
    isocode: 'IS',
    name: 'Ísland',
    prefix: '+354',
  },
  {
    isocode: 'IT',
    name: 'Italia',
    prefix: '+39',
  },
  {
    isocode: 'JE',
    name: 'Jersey',
    prefix: '+44-1534',
  },
  {
    isocode: 'JM',
    name: 'Jamaica',
    prefix: '+1-876',
  },
  {
    isocode: 'JO',
    name: 'الأردن',
    prefix: '+962',
  },
  {
    isocode: 'JP',
    name: '日本',
    prefix: '+81',
  },
  {
    isocode: 'KE',
    name: 'Kenya',
    prefix: '+254',
  },
  {
    isocode: 'KG',
    name: 'Кыргызстан',
    prefix: '+996',
  },
  {
    isocode: 'KH',
    name: 'Kâmpŭchéa',
    prefix: '+855',
  },
  {
    isocode: 'KI',
    name: 'Kiribati',
    prefix: '+686',
  },
  {
    isocode: 'KM',
    name: 'Komori',
    prefix: '+269',
  },
  {
    isocode: 'KN',
    name: 'Saint Kitts and Nevis',
    prefix: '+1-869',
  },
  {
    isocode: 'KP',
    name: '북한',
    prefix: '+850',
  },
  {
    isocode: 'KR',
    name: '대한민국',
    prefix: '+82',
  },
  {
    isocode: 'KW',
    name: 'الكويت',
    prefix: '+965',
  },
  {
    isocode: 'KY',
    name: 'Cayman Islands',
    prefix: '+1-345',
  },
  {
    isocode: 'KZ',
    name: 'Қазақстан',
    prefix: '+7',
  },
  {
    isocode: 'LA',
    name: 'ສປປລາວ',
    prefix: '+856',
  },
  {
    isocode: 'LB',
    name: 'لبنان',
    prefix: '+961',
  },
  {
    isocode: 'LC',
    name: 'Saint Lucia',
    prefix: '+1-758',
  },
  {
    isocode: 'LI',
    name: 'Liechtenstein',
    prefix: '+423',
  },
  {
    isocode: 'LK',
    name: 'śrī laṃkāva',
    prefix: '+94',
  },
  {
    isocode: 'LR',
    name: 'Liberia',
    prefix: '+231',
  },
  {
    isocode: 'LS',
    name: 'Lesotho',
    prefix: '+266',
  },
  {
    isocode: 'LT',
    name: 'Lietuva',
    prefix: '+370',
  },
  {
    isocode: 'LU',
    name: 'Luxembourg',
    prefix: '+352',
  },
  {
    isocode: 'LV',
    name: 'Latvija',
    prefix: '+371',
  },
  {
    isocode: 'LY',
    name: '‏ليبيا',
    prefix: '+218',
  },
  {
    isocode: 'MA',
    name: 'المغرب',
    prefix: '+212',
  },
  {
    isocode: 'MD',
    name: 'Moldova',
    prefix: '+373',
  },
  {
    isocode: 'MG',
    name: 'Madagasikara',
    prefix: '+261',
  },
  {
    isocode: 'MH',
    name: 'M̧ajeļ',
    prefix: '+692',
  },
  {
    isocode: 'MK',
    name: 'Македонија',
    prefix: '+389',
  },
  {
    isocode: 'ML',
    name: 'Mali',
    prefix: '+223',
  },
  {
    isocode: 'MM',
    name: 'Myanma',
    prefix: '+95',
  },
  {
    isocode: 'MN',
    name: 'Монгол улс',
    prefix: '+976',
  },
  {
    isocode: 'MO',
    name: '澳門',
    prefix: '+853',
  },
  {
    isocode: 'MP',
    name: 'Northern Mariana Islands',
    prefix: '+1-670',
  },
  {
    isocode: 'MQ',
    name: 'Martinique',
    prefix: '+596',
  },
  {
    isocode: 'MR',
    name: 'موريتانيا',
    prefix: '+222',
  },
  {
    isocode: 'MS',
    name: 'Montserrat',
    prefix: '+1-664',
  },
  {
    isocode: 'MT',
    name: 'Malta',
    prefix: '+356',
  },
  {
    isocode: 'MU',
    name: 'Maurice',
    prefix: '+230',
  },
  {
    isocode: 'MV',
    name: 'Maldives',
    prefix: '+960',
  },
  {
    isocode: 'MW',
    name: 'Malawi',
    prefix: '+265',
  },
  {
    isocode: 'MX',
    name: 'México',
    prefix: '+52',
  },
  {
    isocode: 'MY',
    name: 'Malaysia',
    prefix: '+60',
  },
  {
    isocode: 'MZ',
    name: 'Moçambique',
    prefix: '+258',
  },
  {
    isocode: 'NA',
    name: 'Namibia',
    prefix: '+264',
  },
  {
    isocode: 'NC',
    name: 'Nouvelle-Calédonie',
    prefix: '+687',
  },
  {
    isocode: 'NE',
    name: 'Niger',
    prefix: '+227',
  },
  {
    isocode: 'NF',
    name: 'Norfolk Island',
    prefix: '+672',
  },
  {
    isocode: 'NG',
    name: 'Nigeria',
    prefix: '+234',
  },
  {
    isocode: 'NI',
    name: 'Nicaragua',
    prefix: '+505',
  },
  {
    isocode: 'NL',
    name: 'Nederland',
    prefix: '+31',
  },
  {
    isocode: 'NO',
    name: 'Norge',
    prefix: '+47',
  },
  {
    isocode: 'NP',
    name: 'नेपाल',
    prefix: '+977',
  },
  {
    isocode: 'NR',
    name: 'Nauru',
    prefix: '+674',
  },
  {
    isocode: 'NU',
    name: 'Niuē',
    prefix: '+683',
  },
  {
    isocode: 'NZ',
    name: 'New Zealand',
    prefix: '+64',
  },
  {
    isocode: 'OM',
    name: 'عمان',
    prefix: '+968',
  },
  {
    isocode: 'PA',
    name: 'Panamá',
    prefix: '+507',
  },
  {
    isocode: 'PE',
    name: 'Perú',
    prefix: '+51',
  },
  {
    isocode: 'PF',
    name: 'Polynésie française',
    prefix: '+689',
  },
  {
    isocode: 'PG',
    name: 'Papua Niugini',
    prefix: '+675',
  },
  {
    isocode: 'PH',
    name: 'Pilipinas',
    prefix: '+63',
  },
  {
    isocode: 'PK',
    name: 'Pakistan',
    prefix: '+92',
  },
  {
    isocode: 'PL',
    name: 'Polska',
    prefix: '+48',
  },
  {
    isocode: 'PM',
    name: 'Saint-Pierre-et-Miquelon',
    prefix: '+508',
  },
  {
    isocode: 'PN',
    name: 'Pitcairn Islands',
    prefix: '+64',
  },
  {
    isocode: 'PS',
    name: 'فلسطين',
    prefix: '+970',
  },
  {
    isocode: 'PT',
    name: 'Portugal',
    prefix: '+351',
  },
  {
    isocode: 'PW',
    name: 'Palau',
    prefix: '+680',
  },
  {
    isocode: 'PY',
    name: 'Paraguay',
    prefix: '+595',
  },
  {
    isocode: 'QA',
    name: 'قطر',
    prefix: '+974',
  },
  {
    isocode: 'RE',
    name: 'La Réunion',
    prefix: '+262',
  },
  {
    isocode: 'RO',
    name: 'România',
    prefix: '+40',
  },
  {
    isocode: 'RS',
    name: 'Србија',
    prefix: '+381',
  },
  {
    isocode: 'RU',
    name: 'Россия',
    prefix: '+7',
  },
  {
    isocode: 'RW',
    name: 'Rwanda',
    prefix: '+250',
  },
  {
    isocode: 'SA',
    name: 'العربية السعودية',
    prefix: '+966',
  },
  {
    isocode: 'SB',
    name: 'Solomon Islands',
    prefix: '+677',
  },
  {
    isocode: 'SC',
    name: 'Seychelles',
    prefix: '+248',
  },
  {
    isocode: 'SD',
    name: 'السودان',
    prefix: '+249',
  },
  {
    isocode: 'SE',
    name: 'Sverige',
    prefix: '+46',
  },
  {
    isocode: 'SG',
    name: 'Singapore',
    prefix: '+65',
  },
  {
    isocode: 'SH',
    name: 'Saint Helena',
    prefix: '+290',
  },
  {
    isocode: 'SI',
    name: 'Slovenija',
    prefix: '+386',
  },
  {
    isocode: 'SK',
    name: 'Slovensko',
    prefix: '+421',
  },
  {
    isocode: 'SL',
    name: 'Sierra Leone',
    prefix: '+232',
  },
  {
    isocode: 'SM',
    name: 'San Marino',
    prefix: '+378',
  },
  {
    isocode: 'SN',
    name: 'Sénégal',
    prefix: '+221',
  },
  {
    isocode: 'SO',
    name: 'Soomaaliya',
    prefix: '+252',
  },
  {
    isocode: 'SR',
    name: 'Suriname',
    prefix: '+597',
  },
  {
    isocode: 'ST',
    name: 'São Tomé e Príncipe',
    prefix: '+239',
  },
  {
    isocode: 'SV',
    name: 'El Salvador',
    prefix: '+503',
  },
  {
    isocode: 'SY',
    name: 'سوريا',
    prefix: '+963',
  },
  {
    isocode: 'SZ',
    name: 'Swaziland',
    prefix: '+268',
  },
  {
    isocode: 'TC',
    name: 'Turks and Caicos Islands',
    prefix: '+1-649',
  },
  {
    isocode: 'TD',
    name: 'Tchad',
    prefix: '+235',
  },
  {
    isocode: 'TF',
    name: 'Territoire des Terres australes et antarctiques françaises',
    prefix: '+262',
  },
  {
    isocode: 'TG',
    name: 'Togo',
    prefix: '+228',
  },
  {
    isocode: 'TH',
    name: 'ประเทศไทย',
    prefix: '+66',
  },
  {
    isocode: 'TJ',
    name: 'Тоҷикистон',
    prefix: '+992',
  },
  {
    isocode: 'TK',
    name: 'Tokelau',
    prefix: '+690',
  },
  {
    isocode: 'TL',
    name: 'Timor-Leste',
    prefix: '+670',
  },
  {
    isocode: 'TM',
    name: 'Türkmenistan',
    prefix: '+993',
  },
  {
    isocode: 'TN',
    name: 'تونس',
    prefix: '+216',
  },
  {
    isocode: 'TO',
    name: 'Tonga',
    prefix: '+676',
  },
  {
    isocode: 'TR',
    name: 'Türkiye',
    prefix: '+90',
  },
  {
    isocode: 'TT',
    name: 'Trinidad and Tobago',
    prefix: '+1-868',
  },
  {
    isocode: 'TV',
    name: 'Tuvalu',
    prefix: '+688',
  },
  {
    isocode: 'TW',
    name: '臺灣',
    prefix: '+886',
  },
  {
    isocode: 'TZ',
    name: 'Tanzania',
    prefix: '+255',
  },
  {
    isocode: 'UA',
    name: 'Україна',
    prefix: '+380',
  },
  {
    isocode: 'UG',
    name: 'Uganda',
    prefix: '+256',
  },
  {
    isocode: 'UM',
    name: 'United States Minor Outlying Islands',
    prefix: '+1',
  },
  {
    isocode: 'US',
    name: 'United States',
    prefix: '+1',
  },
  {
    isocode: 'UY',
    name: 'Uruguay',
    prefix: '+598',
  },
  {
    isocode: 'UZ',
    name: 'O‘zbekiston',
    prefix: '+998',
  },
  {
    isocode: 'VA',
    name: 'Sancta Sedes',
    prefix: '+379',
  },
  {
    isocode: 'VC',
    name: 'Saint Vincent and the Grenadines',
    prefix: '+1-784',
  },
  {
    isocode: 'VE',
    name: 'Venezuela',
    prefix: '+58',
  },
  {
    isocode: 'VG',
    name: 'British Virgin Islands',
    prefix: '+1-284',
  },
  {
    isocode: 'VI',
    name: 'Virgin Islands of the United States',
    prefix: '+1-340',
  },
  {
    isocode: 'VN',
    name: 'Việt Nam',
    prefix: '+84',
  },
  {
    isocode: 'VU',
    name: 'Vanuatu',
    prefix: '+678',
  },
  {
    isocode: 'WF',
    name: 'Wallis et Futuna',
    prefix: '+681',
  },
  {
    isocode: 'WS',
    name: 'Samoa',
    prefix: '+685',
  },
  // {
  //   isocode: "XC",
  //   name: "Ceuta and Melilla",
  //   prefix: "+34",
  // },
  // {
  //   isocode: "XE",
  //   name: "Australian Oceania",
  //   prefix: "+61",
  // },
  {
    isocode: 'XK',
    name: 'Republika e Kosovës',
    prefix: '+383',
  },
  {
    isocode: 'XL',
    name: 'Melilla',
    prefix: '+34',
  },
  // {
  //   isocode: "XM",
  //   name: "Montenegro",
  //   prefix: "+382",
  // },
  {
    isocode: 'YE',
    name: 'اليَمَن',
    prefix: '+967',
  },
  // {
  //   isocode: "YU",
  //   name: "Yugoslavia",
  //   prefix: "+381",
  // },
  {
    isocode: 'ZA',
    name: 'South Africa',
    prefix: '+27',
  },
  {
    isocode: 'ZM',
    name: 'Zambia',
    prefix: '+260',
  },
  // {
  //   isocode: "ZR",
  //   name: "Zaire",
  //   prefix: "+243",
  // },
  {
    isocode: 'ZW',
    name: 'Zimbabwe',
    prefix: '+263',
  },
];

export const countriesList = Countries?.map(({ isocode, name }) => ({
  label: `${isocode} - ${name}`,
  value: isocode,
}));
