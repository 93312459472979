import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ContactAssistant,
  Navbar,
  UserSettingsContent,
  UserSettingsMenu,
  Visibility,
} from '@grimme/components';
import { signIn, signOut, useSession } from '@grimme/next-grimme-auth';
import theme from '@grimme/theme';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRef, useState } from 'react';
import localesRaw from '../../locales.json';
import {
  checkAssistantData,
  getCookie,
  mapContactLinks,
  reformatWhatsappNumber,
  setCookie,
} from './utils';
import { updateProfileLanguage } from '@/api/profile/update-profile-language';
import { environment } from '@/environment';
import {
  TContactDetails,
  useContactDetails,
  useFormattedProfileData,
  useGeolocation,
  useProfile,
} from '@/hooks';
import { GlobalSettings } from '@/lib/butter-types';
import {
  CONTACT_ASSISTANT_COOKIE,
  DEFAULT_LOCALE,
  DEFAULT_MOBILE_PHONE,
  LOCALE_COOKIE,
} from '@/utils';
import { UserGreeting } from './user-greeting/user-greeting';
import { UserMenu } from './user-menu/user-menu';
import { useTranslation } from 'next-i18next';
import { WhatsappConsentDialog } from '../whatsapp-consent-dialog';
import { useRouter } from 'next/router';

const classes = generateUtilityClasses('SwitchableContactAssistant', [
  'logo',
  'userIcon',
]);

const Root = styled('div')(() => ({
  [`& .${classes.logo}`]: {
    margin: theme.spacing(0, 1),
    minHeight: 'auto',
    width: 120,
    cursor: 'pointer',
  },
  [`& .${classes.userIcon}`]: {
    color: theme.palette.common.white,
  },
}));

export type TNavbarWithContact = {
  greeting: GlobalSettings['greeting'];
  loggedInMenu: GlobalSettings['logged_in_menu'];
  loggedOutMenu: GlobalSettings['logged_out_menu'];
  more: GlobalSettings['more'];
  navbarData: GlobalSettings['navbar'];
  navigationLinks: GlobalSettings['navigation_menu'];
  socialLinks: GlobalSettings['social_links'];
  topLinks: GlobalSettings['top_links'];
  withoutContactAssistant?: boolean;
};

//TODO: break down this file, it's just too big
export const NavbarWithContact = (props: TNavbarWithContact) => {
  const {
    greeting,
    loggedInMenu,
    loggedOutMenu,
    more,
    navbarData,
    navigationLinks,
    socialLinks,
    topLinks,
    withoutContactAssistant,
  } = props;
  const [whatsAppConsent, setWhatsAppConsent] = useState(
    Boolean(
      typeof localStorage !== 'undefined' &&
        localStorage.getItem('allowWhatsapp'),
    ),
  );
  const { data: profile } = useProfile();

  const { isLoading, isUserLoggedIn } = useFormattedProfileData(more);

  const shouldShowContactOnMount = Boolean(getCookie(CONTACT_ASSISTANT_COOKIE));
  const [showConsentDialog, setShowConsentDialog] = useState<boolean>(false);
  const [isShowingContact, setIsShowingContact] = useState<boolean>(
    shouldShowContactOnMount,
  );
  const [isShowingUserSettings, setIsShowingUserSettings] =
    useState<boolean>(false);
  const userIconRef = useRef<HTMLButtonElement>(null);

  const insights = useAppInsightsContext();
  const geolocation = useGeolocation();
  const router = useRouter();
  const { t } = useTranslation();

  const { data: contactAssistantData, error } = useContactDetails();
  const { data: sessionData } = useSession();

  const assistantName = `${contactAssistantData?.firstName} ${contactAssistantData?.lastName}`;
  const contactNumber =
    contactAssistantData?.phone || contactAssistantData?.mobilePhone;
  const selectWhatsappNumber =
    contactAssistantData?.mobilePhone || DEFAULT_MOBILE_PHONE;
  const whatsappNumber = reformatWhatsappNumber(selectWhatsappNumber);
  const hasContactAssistant = checkAssistantData(
    error,
    contactAssistantData as TContactDetails,
  );

  const showContactAssistant = Boolean(
    !withoutContactAssistant && hasContactAssistant,
  );

  const toggleContactAssistant = () => {
    //cookie has to be a string;
    const assistantCookie = !isShowingContact ? 'enabled' : '';
    setIsShowingContact(!isShowingContact);
    setCookie(CONTACT_ASSISTANT_COOKIE, assistantCookie);
  };
  const onEmailClick = () => {
    window.open(`mailto: ${contactAssistantData?.email}`, '_self');
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'email',
        rep: contactAssistantData?.email,
      },
    });
  };
  const onPhoneClick = () => {
    window.open(`tel:${contactNumber}`);
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'phone',
        rep: contactNumber,
      },
    });
  };
  const onWhatsAppClick = () => {
    if (!whatsAppConsent) {
      setShowConsentDialog(true);
    } else {
      window.open(`https://wa.me/${whatsappNumber}`);
      insights?.trackEvent({
        name: 'Contacted Rep',
        properties: {
          method: 'whatsapp',
          rep: whatsappNumber,
        },
      });
    }
  };

  const onLanguageChange = (lang: string) => {
    const locale = lang ? lang : DEFAULT_LOCALE;
    router.push({ pathname: router.pathname }, router.asPath, {
      locale,
    });
    setCookie(LOCALE_COOKIE, locale);
    if (isUserLoggedIn && profile) {
      updateProfileLanguage(
        sessionData?.accessToken as string,
        locale,
        profile,
      );
    }
  };

  const assistantDetailsData = [
    ...(contactNumber
      ? [
          {
            description: contactNumber,
            name: 'Phone',
            onClick: onPhoneClick,
            subtitle: `${contactNumber}`,
            title: 'Phone',
            trackingData: { method: 'phone', rep: `${contactNumber}` },
          },
        ]
      : []),
    ...(contactAssistantData?.email
      ? [
          {
            description: contactAssistantData?.email,
            name: 'Email',
            onClick: onEmailClick,
            subtitle: `${contactAssistantData?.email}`,
            title: 'Email',
            trackingData: {
              method: 'email',
              rep: `${contactAssistantData?.email}`,
            },
          },
        ]
      : []),
    ...(whatsappNumber
      ? [
          {
            description: 'Open Chat',
            name: 'WhatsApp',
            onClick: onWhatsAppClick,
            subtitle: t('myGRIMME_website_whatsapp_cta', 'Zum Chat'),
            title: 'WhatsApp',
            trackingData: { method: 'whatsapp', rep: whatsappNumber },
          },
        ]
      : []),
  ];

  const parsedNavigationLinks = navigationLinks
    .map((l) => l.fields)
    .map((link) => ({
      label: link.label,
      url: link.url,
      icon: (
        <FontAwesomeIcon
          icon={
            {
              prefix: link.icon_type,
              iconName: link.icon,
            } as IconLookup
          }
        />
      ),
      visibility: 'both' as Visibility,
      subitems:
        link.sub_items.length === 0
          ? undefined
          : link.sub_items.map((subitem) => ({
              label: subitem.label,
              url: subitem.url,
              visibility: subitem.visibility as Visibility,
            })),
    }));

  const parsedSocialLinks = socialLinks.links.map((socialLink) => ({
    icon: (
      <FontAwesomeIcon
        icon={
          {
            prefix: socialLink.icon_type,
            iconName: socialLink.icon,
          } as IconLookup
        }
      />
    ),
    url: socialLink.url,
  }));

  const mappedContactLinks = mapContactLinks(assistantDetailsData);

  const assistantDetails = {
    avatar: contactAssistantData?.photo
      ? `data:image/jpeg;base64,${contactAssistantData?.photo}`
      : '/assets/img/contact_avatar.svg',
    description: t(
      'myGRIMME_website_contact_assistant_description',
      'Beratung',
    ),
    name: assistantName,
  };

  const assistantDetailsLinks = assistantDetailsData?.map((link) => ({
    description: link?.description,
    name: link?.name,
    onClick: link?.onClick,
    trackingData: link?.trackingData,
  }));

  const handleOnRegistration = () => {
    router.push(`${environment.registrationLink}/?language=${router.locale}`);
  };

  return (
    <Root>
      <Navbar
        additionalLinks={navbarData.additional_links}
        isAuthenticated={isUserLoggedIn}
        links={parsedNavigationLinks}
        onLogoClick={() => router.push('/')}
        socialLinks={parsedSocialLinks}
        topLinks={topLinks.links}
        topLinksLabel={topLinks.title}
      >
        <UserGreeting
          greeting={greeting}
          more={more}
          ref={userIconRef}
          setIsShowingUserSettings={setIsShowingUserSettings}
        />
        <UserSettingsMenu
          anchorEl={userIconRef.current}
          onBackdropClick={() => setIsShowingUserSettings(false)}
          open={isShowingUserSettings}
        >
          <UserSettingsContent
            MenuComponent={
              <UserMenu
                loggedInMenu={loggedInMenu}
                loggedOutMenu={loggedOutMenu}
                more={more}
              />
            }
            contactLinks={mappedContactLinks}
            contactName={assistantName}
            geolocation={geolocation}
            isLoading={isLoading}
            isSessionValid={isUserLoggedIn}
            isShowingContact={isShowingContact}
            onClose={() => setIsShowingUserSettings(false)}
            onHideContact={toggleContactAssistant}
            onLanguageChange={onLanguageChange}
            onLogin={signIn}
            onLogout={signOut}
            onRegister={handleOnRegistration}
            supportedLanguages={localesRaw}
          />
        </UserSettingsMenu>
      </Navbar>
      {showContactAssistant && (
        <span
          id="ID_CONTACT_ASSISTANT"
          className="data-[hide=true]:[&>div]:translate-y-[80px]"
        >
          <ContactAssistant
            details={assistantDetails}
            links={assistantDetailsLinks}
            className="!z-20"
            indented
            onVisibilityChange={toggleContactAssistant}
            visible={!isShowingContact}
          />
        </span>
      )}
      <WhatsappConsentDialog
        contactNumber={whatsappNumber}
        open={showConsentDialog}
        onCancel={() => {
          setShowConsentDialog(false);
        }}
        onConfirm={() => {
          setWhatsAppConsent(true);
          setShowConsentDialog(false);
        }}
      />
    </Root>
  );
};
