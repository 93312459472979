import { BlogPostList, LandingPageData } from '@/lib/butter-types';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Teaser, TeaserCard } from '@grimme/components';
import theme from '@grimme/theme';
import {
  Box,
  Container,
  generateUtilityClasses,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import Link from 'next/link';
import { useState } from 'react';
import { ButterImage } from '../butter-image/butter-image';
import { useTranslation } from 'next-i18next';
import Button from '@grimme/ui-components/button';

export const classes = generateUtilityClasses('NewsSection', [
  'box',
  'butterImage',
  'description',
]);

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  [`& .${classes.butterImage}`]: {
    objectFit: 'cover',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.description}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.box}`]: {
    marginTop: theme.spacing(3),
  },
}));

export function NewsSection(
  data: LandingPageData['news'] & { feed: BlogPostList['data'] },
) {
  const { title, description, button_label, button_url, feed } = data;
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  return (
    <section>
      <Container>
        <Grid
          container
          spacing={{ xs: '12px', sm: '16px', lg: '24px' }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <StyledGrid item xs={4} sm={8}>
            <Typography component="h1" className="g-typography-h2">
              {title}
            </Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
            <Box className={classes.box}>
              <Link href={button_url} passHref>
                <Button variant="primary">
                  {button_label}
                  <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
                </Button>
              </Link>
            </Box>
          </StyledGrid>
        </Grid>
      </Container>
      <StyledBox>
        <Teaser>
          {feed.map((post, index) => (
            <TeaserCard
              key={index}
              title={post.title}
              image={
                <ButterImage
                  className={classes.butterImage}
                  src={post.featured_image ?? ''}
                  alt={post.featured_image_alt}
                  layout="fill"
                  // TODO: add placeholder | showPlaceholder
                  sizes={`(max-width: ${theme.breakpoints.values.md}px) 100vw,
                  50vw`}
                />
              }
              description={post.summary}
              button_label={t(
                'myGRIMME_website_news_section_read_post_button',
                'Mehr Lesen',
              )}
              // button_link={`/${i18n.language}/news/${post.slug}`}
              button_link={`/news/${post.slug}`}
              expanded={expandedIndex === index}
              onClick={(isExpanded) =>
                setExpandedIndex(isExpanded ? null : index)
              }
            />
          ))}
        </Teaser>
      </StyledBox>
    </section>
  );
}
