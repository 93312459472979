import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Slider } from '@grimme/components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Box, Container, generateUtilityClasses, styled } from '@mui/material';
import { useState } from 'react';
import { environment } from '@/environment';
import { TProducts } from '@/lib/products';
import { Tabs } from './tabs';
import { useRouter } from 'next/router';
import Button from '@grimme/ui-components/button';
import Link from 'next/link';

export const classes = generateUtilityClasses('ServicePartnersQuickSearch', [
  'buttonBox',
  'categoryBox',
]);

const StyledBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [`& .${classes.buttonBox}`]: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  [`& .${classes.categoryBox}`]: {
    maxWidth: '100%',
  },
}));

export const ProductDetailsPreview = (props: {
  button_label: string;
  button_link: string;
  productsDetails: {
    products: TProducts;
    category: string;
  }[];
}) => {
  const { button_label, button_link, productsDetails } = props;
  const router = useRouter();

  const insights = useAppInsightsContext();

  const [selectedCategory, setSelectCategory] = useState<number>(0);

  const handleOnClickSeeMore = () => {
    insights?.trackEvent({
      name: 'Discover all Machines',
      properties: {
        category: productsDetails[selectedCategory].category,
      },
    });
  };

  return (
    <Container>
      <StyledBox>
        <Tabs
          categories={productsDetails.map((p) => ({ name: p.category }))}
          value={selectedCategory}
          setValue={setSelectCategory}
        />
        {productsDetails.map(
          (detail, index) =>
            selectedCategory === index && (
              <Box className={classes.categoryBox} key={detail.category}>
                <Slider>
                  {detail.products.map((product) => (
                    <Slider.Slide
                      key={product.id}
                      type="product"
                      data={{
                        id: product.id,
                        name: product.title,
                        features: product.subtitle,
                        image: `${environment.blobStorageUrl}product-preview/${product.id}`,
                        href: `${environment.onlineSalesUrl}/${router.locale}/p/${product.slug}`,
                        type: product.category,
                      }}
                    />
                  ))}
                </Slider>
              </Box>
            ),
        )}
        <Box className={classes.buttonBox}>
          <Button asChild variant="outline" onClick={handleOnClickSeeMore}>
            <Link href={button_link}>
              {button_label}
              <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
            </Link>
          </Button>
        </Box>
      </StyledBox>
    </Container>
  );
};
